<template>
  <ion-page>

    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="!isLoading">

      <ion-row class="sideMargins" style="margin-top: 40px;">

        <ion-col size="12">
          <ion-label class="title" style="margin-left: 17px;">Registrierung</ion-label>
        </ion-col>
        <ion-col size="12" v-if="errorWhileCreating">
          <ion-card>
            <p>Etwas ist schiefgelaufen. Überprüfe deine Angaben und versuche es nochmal.</p>
            <small>{{ errorMsg }}</small>
          </ion-card>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Benutzername">Benutzername *</ion-label>
            <small v-if="showUserAvailability" :style="usernameAvailabilityStyle">{{ usernameAvailabilityMessage }}</small>
            <ion-input v-model="username" @keyup="checkUserNameAvailability"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-label position="stacked" placeholder="Firstname">Vorname *</ion-label>
            <ion-input v-model="firstname"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-label position="stacked" placeholder="Lastname">Nachname *</ion-label>
            <ion-input v-model="lastname"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Birthdate">Geburtsdatum *</ion-label>
            <ion-datetime display-format="DD.MM.YYYY" :min="minimumDate" :max="maximumDate" v-model="userBirthdate"></ion-datetime>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="E-Mail">E-Mail *</ion-label>
            <small v-if="showEmailValidation" style="color: red;">{{ emailValidationMessage }}</small>
            <ion-input v-model="userEmail" @keyup="checkEmail"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Passwort">Passwort *</ion-label>
            <small v-if="showPasswordValidation" style="color: red;">{{ passwordValidationMessage }}</small>
            <ion-input v-model="userPassword" @keyup="checkPassword" type="password"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Repeat Passwort">Passwort wiederholen *</ion-label>
            <small v-if="showPasswordRepeatValidation" style="color: red;">{{ passwordRepeatValidationMessage }}</small>
            <ion-input v-model="userRepeatPassword" @keyup="checkRepeatPassword" type="password"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12" style="margin-top: 10px;">
          <ion-button expand="block" style="margin-left: 17px;" @click="validateUserInput()" :disabled="!usernameIsAvailable || !passwordIsValid || !repeatPasswordIsMatching || !emailIsValid">Account erstellen</ion-button>
        </ion-col>
        <ion-col size="12" style="margin-top: 35px;">
          <ion-card-subtitle style="margin-left: 17px;">Du hast bereits einen Account?</ion-card-subtitle>
          <ion-button expand="block" style="margin-left: 17px;" color="light" router-link="/login">Jetzt Einloggen</ion-button>
        </ion-col>
      </ion-row>

    </ion-content>

    <ion-content v-if="isLoading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonPage, IonRow, IonCol, IonLabel, IonTitle, IonItem, IonInput, IonSpinner, IonCardSubtitle, IonDatetime, IonCard } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'Register',
  data () {
    return {
      username: null,
      firstname: null,
      lastname: null,
      userBirthdate: null,
      userEmail: null,
      userPassword: null,
      userRepeatPassword: null,
      minimumDate: null,
      maximumDate: null,
      isLoading: false,
      usernameTimeout: null,
      usernameAvailabilityStyle: null,
      showUserAvailability: false,
      usernameAvailabilityMessage: null,
      usernameIsAvailable: false,
      passwordTimeout: null,
      passwordValidationMessage: null,
      showPasswordValidation: false,
      passwordRepeatValidationMessage: null,
      showPasswordRepeatValidation: false,
      passwordRepeatTimeout: null,
      repeatPasswordIsMatching: false,
      passwordIsValid: false,
      emailTimeout: null,
      emailValidationMessage: null,
      showEmailValidation: false,
      emailIsValid: false,
      errorMsg: null,
      errorWhileCreating: false,
    }
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonItem,
    IonInput,
    IonSpinner,
    IonCardSubtitle,
    IonDatetime,
    IonCard
  },
  created() {
    this.minimumDate = moment().subtract(99,'years').format('YYYY-MM-DD');
    this.userBirthdate = moment().subtract(18,'years').format('YYYY-MM-DD');
    this.maximumDate = moment().subtract(14,'years').format('YYYY-MM-DD');
  },
  methods: {
    clearUserCookies(){
      localStorage.clear();
    },
    checkUserNameAvailability() {

      clearTimeout(this.usernameTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.usernameTimeout = setTimeout(function () {

        current.showUserAvailability = false;
        current.usernameAvailabilityMessage = null;
        current.usernameAvailabilityStyle = null;
        current.usernameIsAvailable = false;

        // convert input to all lowercase
        current.username = current.username.toLowerCase().trim();

        // check availability
        const formData = new FormData();
        formData.append("username", current.username);
        axios.post(process.env.VUE_APP_API_URL + '/check-username', formData).then(response => {

          current.showUserAvailability = true;

            if (response.data.status) {
              current.usernameAvailabilityMessage = "Benutzername ist verfügbar";
              current.usernameAvailabilityStyle = {
                    'color': 'green'
                };

              current.usernameIsAvailable = true;

            } else {
              current.usernameAvailabilityMessage = "Benutzername nicht verfügbar";
              current.usernameAvailabilityStyle = {
                'color': 'red'
              };
          }
        });

      }, 1000);

    },
    checkPassword() {
      clearTimeout(this.passwordTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.passwordTimeout = setTimeout(function () {

          current.passwordIsValid = false;

          if (current.userPassword) {
            // check if password is shorter than 6 Chars
            if (current.userPassword.length < 6) {
              current.showPasswordValidation = true;
              current.passwordValidationMessage = "Passwort muss länger als 6 Zeichen sein.";
            } else {
              current.showPasswordValidation = false;
              current.passwordIsValid = true;

              // check if repeat password is not empty
              if (current.userRepeatPassword !== "") {
                current.checkRepeatPassword();
              }

            }

            if (current.userPassword === "") {
              current.showPasswordValidation = false;
            }
          }


      }, 1000);
    },
    checkRepeatPassword() {
      clearTimeout(this.passwordRepeatTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.passwordRepeatTimeout = setTimeout(function () {

        current.repeatPasswordIsMatching = false;

        if (current.userRepeatPassword) {
          // check if password is not equal to repeat password
          if (current.userPassword !== current.userRepeatPassword) {
            current.showPasswordRepeatValidation = true;
            current.passwordRepeatValidationMessage = "Passwörter müssen übereinstimmen.";
          } else {
            current.showPasswordRepeatValidation = false;
            current.repeatPasswordIsMatching = true;
          }

          if (current.userRepeatPassword === "") {
            current.showPasswordRepeatValidation = false;
          }
        }

      }, 1000);
    },
    checkEmail() {
      clearTimeout(this.emailTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.emailTimeout = setTimeout(function () {

        current.emailIsValid = false;

        // check if password is not equal to repeat password
        if (!current.validateEmail(current.userEmail)) {
          current.showEmailValidation = true;
          current.emailValidationMessage = "Keine gültige E-Mail.";
        } else {
          current.showEmailValidation = false;
          current.emailIsValid = true;
        }

        if (current.userEmail === "") {
          current.showEmailValidation = false;
        }

      }, 1000);
    },
    validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    },
    validateUserInput() {

      let errors = 0;

      if (!this.usernameIsAvailable) {
          console.log("Please enter Username");
          errors++;
      }

      if (this.firstname === "") {
          console.log("Please enter Firstname");
        errors++;
      }

      if (this.lastname === "") {
          console.log("Please enter Lastname");
        errors++;
      }

      if (!this.emailIsValid) {
        console.log("Please enter a valid email");
        errors++;
      }

      if (!this.passwordIsValid) {
        console.log("Please enter valid Password");
        errors++;
      }

      if (!this.repeatPasswordIsMatching) {
        console.log("Please enter a matching repeat Password");
        errors++;
      }


      if (errors == 0) {
          this.registerAction();
      }


    },
    registerAction() {
        this.isLoading = true;
        this.errorWhileCreating = false;

        const data = {
            "username": this.username,
            "password": this.userPassword,
            "birthdate": this.userBirthdate,
            "firstname": this.firstname,
            "lastname": this.lastname,
            "email": this.userEmail
        };

      axios.post(process.env.VUE_APP_API_URL + '/users', data)
          .then(() => {
              // redirect to confirm page
              this.$router.push({ path: '/register/confirm'});
          })
          .catch(error => {
              this.errorMsg = error.message;
              this.errorWhileCreating = true;
              this.isLoading = false;
          });

    },
  }
});

</script>

<style scoped>

ion-spinner {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.rounded-image {
  border-radius: 50%;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 25%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bottomMargins {
  margin-bottom: 25px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.price {
  font-size: 20px;
  font-weight: bold;
}

.roundedCorners {
  border-radius: 10px;
}

</style>