
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonPage, IonRow, IonCol, IonLabel, IonTitle, IonItem, IonInput, IonSpinner, IonCardSubtitle, IonDatetime, IonCard } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'Register',
  data () {
    return {
      username: null,
      firstname: null,
      lastname: null,
      userBirthdate: null,
      userEmail: null,
      userPassword: null,
      userRepeatPassword: null,
      minimumDate: null,
      maximumDate: null,
      isLoading: false,
      usernameTimeout: null,
      usernameAvailabilityStyle: null,
      showUserAvailability: false,
      usernameAvailabilityMessage: null,
      usernameIsAvailable: false,
      passwordTimeout: null,
      passwordValidationMessage: null,
      showPasswordValidation: false,
      passwordRepeatValidationMessage: null,
      showPasswordRepeatValidation: false,
      passwordRepeatTimeout: null,
      repeatPasswordIsMatching: false,
      passwordIsValid: false,
      emailTimeout: null,
      emailValidationMessage: null,
      showEmailValidation: false,
      emailIsValid: false,
      errorMsg: null,
      errorWhileCreating: false,
    }
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonItem,
    IonInput,
    IonSpinner,
    IonCardSubtitle,
    IonDatetime,
    IonCard
  },
  created() {
    this.minimumDate = moment().subtract(99,'years').format('YYYY-MM-DD');
    this.userBirthdate = moment().subtract(18,'years').format('YYYY-MM-DD');
    this.maximumDate = moment().subtract(14,'years').format('YYYY-MM-DD');
  },
  methods: {
    clearUserCookies(){
      localStorage.clear();
    },
    checkUserNameAvailability() {

      clearTimeout(this.usernameTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.usernameTimeout = setTimeout(function () {

        current.showUserAvailability = false;
        current.usernameAvailabilityMessage = null;
        current.usernameAvailabilityStyle = null;
        current.usernameIsAvailable = false;

        // convert input to all lowercase
        current.username = current.username.toLowerCase().trim();

        // check availability
        const formData = new FormData();
        formData.append("username", current.username);
        axios.post(process.env.VUE_APP_API_URL + '/check-username', formData).then(response => {

          current.showUserAvailability = true;

            if (response.data.status) {
              current.usernameAvailabilityMessage = "Benutzername ist verfügbar";
              current.usernameAvailabilityStyle = {
                    'color': 'green'
                };

              current.usernameIsAvailable = true;

            } else {
              current.usernameAvailabilityMessage = "Benutzername nicht verfügbar";
              current.usernameAvailabilityStyle = {
                'color': 'red'
              };
          }
        });

      }, 1000);

    },
    checkPassword() {
      clearTimeout(this.passwordTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.passwordTimeout = setTimeout(function () {

          current.passwordIsValid = false;

          if (current.userPassword) {
            // check if password is shorter than 6 Chars
            if (current.userPassword.length < 6) {
              current.showPasswordValidation = true;
              current.passwordValidationMessage = "Passwort muss länger als 6 Zeichen sein.";
            } else {
              current.showPasswordValidation = false;
              current.passwordIsValid = true;

              // check if repeat password is not empty
              if (current.userRepeatPassword !== "") {
                current.checkRepeatPassword();
              }

            }

            if (current.userPassword === "") {
              current.showPasswordValidation = false;
            }
          }


      }, 1000);
    },
    checkRepeatPassword() {
      clearTimeout(this.passwordRepeatTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.passwordRepeatTimeout = setTimeout(function () {

        current.repeatPasswordIsMatching = false;

        if (current.userRepeatPassword) {
          // check if password is not equal to repeat password
          if (current.userPassword !== current.userRepeatPassword) {
            current.showPasswordRepeatValidation = true;
            current.passwordRepeatValidationMessage = "Passwörter müssen übereinstimmen.";
          } else {
            current.showPasswordRepeatValidation = false;
            current.repeatPasswordIsMatching = true;
          }

          if (current.userRepeatPassword === "") {
            current.showPasswordRepeatValidation = false;
          }
        }

      }, 1000);
    },
    checkEmail() {
      clearTimeout(this.emailTimeout);

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const current = this;

      // Make a new timeout set to go off in 1000ms (1 second)
      this.emailTimeout = setTimeout(function () {

        current.emailIsValid = false;

        // check if password is not equal to repeat password
        if (!current.validateEmail(current.userEmail)) {
          current.showEmailValidation = true;
          current.emailValidationMessage = "Keine gültige E-Mail.";
        } else {
          current.showEmailValidation = false;
          current.emailIsValid = true;
        }

        if (current.userEmail === "") {
          current.showEmailValidation = false;
        }

      }, 1000);
    },
    validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    },
    validateUserInput() {

      let errors = 0;

      if (!this.usernameIsAvailable) {
          console.log("Please enter Username");
          errors++;
      }

      if (this.firstname === "") {
          console.log("Please enter Firstname");
        errors++;
      }

      if (this.lastname === "") {
          console.log("Please enter Lastname");
        errors++;
      }

      if (!this.emailIsValid) {
        console.log("Please enter a valid email");
        errors++;
      }

      if (!this.passwordIsValid) {
        console.log("Please enter valid Password");
        errors++;
      }

      if (!this.repeatPasswordIsMatching) {
        console.log("Please enter a matching repeat Password");
        errors++;
      }


      if (errors == 0) {
          this.registerAction();
      }


    },
    registerAction() {
        this.isLoading = true;
        this.errorWhileCreating = false;

        const data = {
            "username": this.username,
            "password": this.userPassword,
            "birthdate": this.userBirthdate,
            "firstname": this.firstname,
            "lastname": this.lastname,
            "email": this.userEmail
        };

      axios.post(process.env.VUE_APP_API_URL + '/users', data)
          .then(() => {
              // redirect to confirm page
              this.$router.push({ path: '/register/confirm'});
          })
          .catch(error => {
              this.errorMsg = error.message;
              this.errorWhileCreating = true;
              this.isLoading = false;
          });

    },
  }
});

